import "./StoryPage.css";
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Navbar from "../components/Navbar.jsx";
import Footer from "../components/Footer.jsx";
import Event from '../components/Event';
import Ellipse from "../assets/images/ellipse.png";


function StoryPage() {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/Announcements');
    };

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, []); // Run this effect only once, on component mount

    const location = useLocation();
    const {
        fstory_imgsrc,
        fstory_mainTitle,
        fstory_secondTitle,
        fstory_eventDate,
        fstory_paragraph
    } = location.state; 

    console.log(fstory_imgsrc);

    return (
        <div>
            <Navbar/>

            <img src={Ellipse} alt="ellipse shape" className="ellipse-4" />
            <div className="story-page-container">
                <Event 
                    imgsrc={fstory_imgsrc}
                    mainTitle={fstory_mainTitle} 
                    secondTitle={fstory_secondTitle}
                    eventDate={fstory_eventDate}
                    paragraph={fstory_paragraph}
                    isStoryPage={true}
                />

                <div className="fs-button-div">
                    <button className="full-story-back-btn" onClick={handleClick}>Back</button>
                </div>
            </div>

            <Footer/>
        </div>
    );
}

export default StoryPage;
