import './Facilities.css';
import React from 'react'

import Navbar from "../components/Navbar"
import Footer from "../components/Footer"

import SportsLogo from '../assets/images/sports-pavilion-logo-white.png'
import Tennis from "../assets/images/tennis2.png"
import Pickleball from "../assets/images/pickleball.png"
import Basketball from '../assets/images/basketball.png'
import Billiards from '../assets/images/billiards.png'
import Darts from '../assets/images/darts-pingpong.png'
import SwimmingPool from "../assets/images/swimming-pool2.png"
import GolfCourse from '../assets/images/golf-course2.png'
import Golf1 from '../assets/images/golf-1.png'
// import Golf2 from '../assets/images/golf-2.png'
import Golf3 from '../assets/images/golf-3.png'
// import Golf4 from '../assets/images/golf-4.png'
// import Golf5 from '../assets/images/golf-5.png'


export default function Facilities() {
  return (
    <div>
      <Navbar />
      <div className="facilities-container">
      <img src={SportsLogo} alt='Sports Pavillon Logo' className='sports-logo-1'/>
        <div className="title-logo-wrap">
          <h1 className="facilities-and-amenities">FACILITIES AND AMENITIES</h1>
          <img src={SportsLogo} alt='Sports Pavillon Logo' className='sports-logo-2'/>
        </div>
        <div className="facilities-upper-part">
          <div className="facilities-upper-part-1">
            <div className="facilities-titles">
              <h3 className="sports-facilities">SPORTS FACILITIES</h3>
              <h5 className="facilities-subtitle f-subtitle-1">ELEVATING YOUR ACTIVE LIFESTYLE</h5>
              <h6 className="facilities-subtitle f-subtitle-2">OPEN DAILY FROM 8 AM - 10 PM</h6>
              <p className="facilities-about">
                Experience a world of sports excellence at Alta Vista Golf and Country Club's premier sports facilities. Our state-of-the-art amenities cater to a variety of athletic pursuits, offering something for every sports enthusiast. Whether you're seeking to hone your skills, stay active, or simply unwind with friends, our facilities provide the perfect setting for all your recreational needs.
              </p>
              <div className="facilities-about">
                Our facilities are exclusive only for members and their guests.
              </div>
            </div>
            <div className="rates-tables">
              <h3 className="rates-title">RATES</h3>

              {/* Tennis Table */}
              <div className="f1-table-container tennis-container">
                <h5 className="f1-table-title tennis-title">Tennis</h5>
                <table className="table-1 tennis">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Per Hour</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Member</th>
                      <th>200.00</th>
                    </tr>
                    <tr>
                      <th>Guest</th>
                      <th>300.00</th>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* Badminton/Pickleball Table */}
              <div className="f1-table-container badminton-container">
                <h5 className="f1-table-title badminton-title">Badminton/Pickleball</h5>
                <table className="table-1 badminton">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Per Hour</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Member</th>
                      <th>200.00</th>
                    </tr>
                    <tr>
                      <th>Guest</th>
                      <th>300.00</th>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* Basketball/Volleyball Table */}
              <div className="f1-table-container bvball-container">
                <h5 className="f1-table-title bvball-title">Basketball/Volleyball</h5>
                <table className="table-1 bvball">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Per Hour</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Member</th>
                      <th>600.00</th>
                    </tr>
                    <tr>
                      <th>Guest</th>
                      <th>800.00</th>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* Billiard/Darts Table */}
              <div className="f1-table-container billdarts-container">
                <h5 className="f1-table-title billdarts-title">Billiard/Darts</h5>
                <table className="table-1 billdarts">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Per Hour (air-conditioned)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Member</th>
                      <th>150.00</th>
                    </tr>
                    <tr>
                      <th>Guest</th>
                      <th>200.00</th>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* Swimming Pool Table */}
              <div className="f1-table-container swimming-container">
                <h5 className="f1-table-title swimming-title">Swimming Pool <span>(Open Tue-Sun from 8 AM - 7 PM)</span></h5>
                <table className="table-1 swimming">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Per Head</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Member</th>
                      <th>Free</th>
                    </tr>
                    <tr>
                      <th>Guest</th>
                      <th>160.00</th>
                    </tr>
                    <tr>
                      <th>Towel</th>
                      <th>Free</th>
                    </tr>
                    {/* <tr>
                      <th>Charge for lost/unreturned towel</th>
                      <th>500.00</th>
                    </tr> */}
                  </tbody>
                </table>
              </div>

              {/* Fitness Gym Table */}
              <div className="f1-table-container gym-container">
                <h5 className="f1-table-title gym-title">Fitness Gym</h5>
                <table className="table-1 gym">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Per Head - Per Hour</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Member</th>
                      <th>Free</th>
                    </tr>
                    <tr>
                      <th>Guest</th>
                      <th>150.00</th>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* Table Tennis Table */}
              <div className="f1-table-container pingpong-container">
                <h5 className="f1-table-title pingpong-title">Table Tennis</h5>
                <table className="table-1 pingpong">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Per Hour</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Member</th>
                      <th>80.00</th>
                    </tr>
                    <tr>
                      <th>Guest</th>
                      <th>100.00</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="facilities-upper-part-2">
            <div className="rates-pictures">
              <img src={Tennis} alt="Tennis Court" className="sports-photo tennis-photo"/>
              <img src={Pickleball} alt="Pickleball court" className="sports-photo pickleball-photo"/>
              <img src={Basketball} alt="Basketball Court" className="sports-photo bball-photo"/>
              <img src={Billiards} alt="Pool table" className="sports-photo billiards-photo"/>
              <img src={Darts} alt="Darts" className="sports-photo darts-photo"/>
            </div>
          </div>
        </div>


        {/* Facilities Sports Rates Ends Here */}


        <div className="facilities-lower-part">
          <div className="facilities-lower-part-1">
            <div className="golf-titles">
                <h3 className="golf-facilities">GOLF FACILITIES</h3>
                <h5 className="golf-subtitle">ENHANCING YOUR GOLF EXPERIENCE</h5>
                <p className="golf-about">
                  Discover an unparalleled golfing experience at Alta Vista Golf and Country Club. Our world-class golf facilities offer enthusiasts and novices alike the perfect blend of challenge and relaxation amidst stunning surroundings. Immerse yourself in the lush greenery of our meticulously maintained courses, designed to cater to players of all skill levels. Whether you're refining your swing on our driving range, strategizing on the fairways, or perfecting your putt on our championship-caliber greens, Alta Vista provides the ultimate destination for golf aficionados seeking excellence in every swing.
                </p>
              </div>
            <div className="golf-rates-tables">
              <h3 className="golf-rates-title">GOLF RATES</h3>

              {/* Member Table */}
              {/* <div className="f2-table-container member-container">
                <h5 className="f2-table-title member-title">Member</h5>
                <table className="table-2 member">
                  <thead>
                    <tr>
                      <th></th>
                      <th>18 holes</th>
                      <th>9 holes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Golf Cart (2-seater: 1 player/1 cart)</th>
                      <th>450.00</th>
                      <th>300.00</th>
                    </tr>
                    <tr>
                      <th>Insurance</th>
                      <th></th>
                      <th></th>
                    </tr>
                    <tr>
                      <th>Caddie (cash)</th>
                      <th>500.00</th>
                      <th>300.00</th>
                    </tr>
                  </tbody>
                </table>
              </div> */}

              {/* Member Senior Table */}
              {/* <div className="f2-table-container senior-container">
                <h5 className="f2-table-title senior-title">Member Senior</h5>
                <table className="table-2 senior">
                  <thead>
                    <tr>
                      <th></th>
                      <th>18 holes</th>
                      <th>9 holes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Golf Cart (2-seater: 1 player/1 cart)</th>
                      <th>360.00</th>
                      <th>240.00</th>
                    </tr>
                    <tr>
                      <th>Insurance</th>
                      <th></th>
                      <th></th>
                    </tr>
                    <tr>
                      <th>Caddie (cash)</th>
                      <th>500.00</th>
                      <th>300.00</th>
                    </tr>
                  </tbody>
                </table>
              </div> */}

              {/* Guest with Member Table */}
              {/* <div className="f2-table-container guest-member-container">
                <h5 className="f2-table-title guest-member-title">Guest <span>(accompanied by a member)</span></h5>
                <table className="table-2 guest-member">
                  <thead>
                    <tr>
                      <th></th>
                      <th>18 holes</th>
                      <th>9 holes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>
                        Green Fee <br />
                        Weekday <br />
                        Weekend/Holiday
                      </th>
                      <th>
                        <br />
                        1,200.00 <br />
                        2,400.00
                      </th>
                      <th>
                        <br />
                        700.00 <br />
                        1,350.00
                      </th>
                    </tr>
                    <tr>
                      <th>Golf Cart (2-seater: 1 player/1 cart)</th>
                      <th>600.00</th>
                      <th>400.00</th>
                    </tr>
                    <tr>
                      <th>Insurance</th>
                      <th>70.00</th>
                      <th>70.00</th>
                    </tr>
                    <tr>
                      <th>Caddie (Cash)</th>
                      <th>500.00</th>
                      <th>300.00</th>
                    </tr>
                  </tbody>
                </table>
              </div> */}

              {/* Guest Senior with Member Table */}
              {/* <div className="f2-table-container senior-member-container">
                <h5 className="f2-table-title senior-member-title">Guest Senior <span>(accompanied by a member)</span></h5>
                <table className="table-2 senior-member">
                  <thead>
                    <tr>
                      <th></th>
                      <th>18 holes</th>
                      <th>9 holes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>
                        Green Fee <br />
                        Weekday <br />
                        Weekend/Holiday
                      </th>
                      <th>
                        <br />
                        960.00 <br />
                        1,920.00
                      </th>
                      <th>
                        <br />
                        560.00 <br />
                        1,080.00
                      </th>
                    </tr>
                    <tr>
                      <th>Golf Cart (2-seater: 1 player/1 cart)</th>
                      <th>480.00</th>
                      <th>320.00</th>
                    </tr>
                    <tr>
                      <th>Insurance</th>
                      <th>70.00</th>
                      <th>70.00</th>
                    </tr>
                    <tr>
                      <th>Caddie (Cash)</th>
                      <th>500.00</th>
                      <th>300.00</th>
                    </tr>
                  </tbody>
                </table>
              </div> */}


              {/* Foreign Tourist Table */}
              <div className="f2-table-container foreign-tourist-container">
                <h5 className="f2-table-title foreign-tourist-title">Tourist <span>(Non-Filipino)</span></h5>
                <table className="table-2 foreign-tourist">
                  <thead>
                    <tr>
                      <th></th>
                      <th>18 holes</th>
                      <th>9 holes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>
                        Green Fee <br />
                        Weekday <br />
                        Weekend/Holiday
                      </th>
                      <th>
                        <br />
                        4,000.00 <br />
                        6,000.00
                      </th>
                      <th>
                        <br />
                        2,000.00 <br />
                        3,000.00
                      </th>
                    </tr>
                    <tr>
                      <th>Golf Cart (2-seater: 1 player/1 cart)</th>
                      <th>1,000.00</th>
                      <th>600.00</th>
                    </tr>
                    <tr>
                      <th>Caddie (Cash)</th>
                      <th>500.00</th>
                      <th>300.00</th>
                    </tr>
                    <tr>
                      <th>Deposit (cash/for restaurant use only)</th>
                      <th>1,000.00</th>
                      <th>1,000.00</th>
                    </tr>
                    <tr>
                      <th>Deposit (refundable)</th>
                      <th>1,000.00</th>
                      <th>1,000.00</th>
                    </tr>
                  </tbody>
                </table>
                <p className='table-footer'>
                Tee time reservation for tourists is 5 days before the playing date.
                </p>
              </div>

              {/* Local Tourist Table */}
              <div className="f2-table-container local-tourist-container">
                <h5 className="f2-table-title local-tourist-title">Tourist <span>(Filipino)</span></h5>
                <table className="table-2 local-tourist">
                  <thead>
                    <tr>
                      <th></th>
                      <th>18 holes</th>
                      <th>9 holes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>
                        ALL IN <br />
                        Weekday <br />
                        Weekend/Holiday
                      </th>
                      <th>
                        <br />
                        4,100.00 <br />
                        6,100.00
                      </th>
                      <th>
                        <br />
                        2,300.00 <br />
                        3,550.00
                      </th>
                    </tr>
                    <tr>
                      <th>Deposit (refundable)</th>
                      <th>1,000.00</th>
                      <th>1,000.00</th>
                    </tr>
                  </tbody>
                </table>
                <p className='table-footer'>
                  Tee time reservation for tourists is 5 days before the playing date.
                </p>
              </div>

              {/* Reciprocity Table */}
              <div className="f2-table-container reciprocity-container">
                <h5 className="f2-table-title reciprocity-title">Reciprocity <span>(Sta. Lucia Golf Club Members)</span></h5>
                <table className="table-2 reciprocity">
                  <thead>
                    <tr>
                      <th></th>
                      <th>18 holes</th>
                      <th>9 holes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>
                        Green Fee <br />
                        Weekday <br />
                        Weekend/Holiday
                      </th>
                      <th>
                        <br />
                        600.00 <br />
                        1,200.00
                      </th>
                      <th>
                        <br />
                        350.00 <br />
                        675.00
                      </th>
                    </tr>
                    <tr>
                      <th>Golf Cart w/ member (1 player/1 cart)</th>
                      <th>600.00</th>
                      <th>400.00</th>
                    </tr>
                    <tr>
                      <th>Golf Cart w/o member (1 player/1 cart)</th>
                      <th>1,000.00</th>
                      <th>600.00</th>
                    </tr>
                    <tr>
                      <th>Insurance</th>
                      <th>100.00</th>
                      <th>100.00</th>
                    </tr>
                    <tr>
                      <th>Caddie (Cash)</th>
                      <th>500.00</th>
                      <th>300.00</th>
                    </tr>
                    <tr>
                      <th>Deposit (refundable)</th>
                      <th>1,000.00</th>
                      <th>1,000.00</th>
                    </tr>
                  </tbody>
                </table>
                <p className='table-footer'>
                  Pinewoods - Baguio, Lakewood City - Nueva Ecija, Royale Tagaytay - Tagaytay, Eagle Ridge - Cavite, South Pacific - Davao, Beverly Place - Pampanga, Summit Point - Batangas
                </p>
              </div>

              {/* Senior Fed with Member Table */}
              {/* <div className="f2-table-container seniorfed-member-container">
                <h5 className="f2-table-title seniorfed-member-title">Senior - <span>Federation of Senior Golfers /Office of the Senior Citizens Affairs (accompanied by a member)</span></h5>
                <table className="table-2 seniorfed-member">
                  <thead>
                    <tr>
                      <th></th>
                      <th>18 holes</th>
                      <th>9 holes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>
                        Green Fee <br />
                        Mon/Wed <br />
                        Tue/Thu/Fri <br />
                        Weekend/Holiday <br />
                        Mon/Wed - Holiday
                      </th>
                      <th>
                        <br />
                        600.00 <br />
                        960.00 <br />
                        1,920.00 <br />
                        1,200.00
                      </th>
                      <th>
                        <br />
                        350.00 <br />
                        560.00 <br />
                        1,080.00 <br />
                        675.00
                      </th>
                    </tr>
                    <tr>
                      <th>Golf Cart (2-seater: 1 player/1 cart)</th>
                      <th>600.00</th>
                      <th>400.00</th>
                    </tr>
                    <tr>
                      <th>Insurance</th>
                      <th>70.00</th>
                      <th>70.00</th>
                    </tr>
                    <tr>
                      <th>Caddie (Cash)</th>
                      <th>500.00</th>
                      <th>300.00</th>
                    </tr>
                  </tbody>
                </table>
              </div> */}

              {/* Senior Fed without Member Table */}
              {/* <div className="f2-table-container seniorfed-nomember-container">
                <h5 className="f2-table-title seniorfed-nomember-title">Senior - <span>Federation of Senior Golfers /Office of the Senior Citizens Affairs (unaccompanied by a member)</span></h5>
                <table className="table-2 seniorfed-nomember">
                  <thead>
                    <tr>
                      <th></th>
                      <th>18 holes</th>
                      <th>9 holes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>
                        Green Fee <br />
                        Mon/Wed <br />
                        Tue/Thu/Fri <br />
                        Weekend/Holiday <br />
                        Mon/Wed - Holiday
                      </th>
                      <th>
                        <br />
                        1,850.00 <br />
                        2,900.00 <br />
                        4,500.00 <br />
                        2,850.00
                      </th>
                      <th>
                        <br />
                        975.00 <br />
                        1,500.00 <br />
                        2,340.00 <br />
                        1,500.00
                      </th>
                    </tr>
                    <tr>
                      <th>Golf Cart (2-seater: 1 player/1 cart)</th>
                      <th>1,000.00</th>
                      <th>600.00</th>
                    </tr>
                    <tr>
                      <th>Caddie (Cash)</th>
                      <th>500.00</th>
                      <th>300.00</th>
                    </tr>
                    <tr>
                      <th>Deposit (cash/refundable/for restaurant use only)</th>
                      <th>1,000.00</th>
                      <th>1,000.00</th>
                    </tr>
                  </tbody>
                </table>
              </div> */}

              {/* Rentals Table */}
              <div className="f2-table-container rentals-container">
                <h5 className="f2-table-title rentals-title">Rentals</h5>
                <table className="table-2 rentals">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Member/Guest</th>
                      <th>Tourist</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Golf set</th>
                      <th>1,200.00</th>
                      <th>1,500.00</th>
                    </tr>
                    <tr>
                      <th>Golf shoes</th>
                      <th>400.00</th>
                      <th>500.00</th>
                    </tr>
                    <tr>
                      <th>Golf bag (empty bag)</th>
                      <th>200.00</th>
                      <th>300.00</th>
                    </tr>
                    <tr>
                      <th>Umbrella</th>
                      <th>100.00</th>
                      <th>200.00</th>
                    </tr>
                    <tr>
                      <th>Rain bag cover</th>
                      <th>80.00</th>
                      <th>100.00</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="facilities-lower-part-2">
            <div className="golf-rates-pictures">
              <img src={GolfCourse} alt="Golf Course Map" className="golf-rates-photo golf-course-photo"/>
              <img src={Golf1} alt="Golf Field" className="golf-rates-photo golf-field-photo"/>
              {/* <img src={Golf2} alt="Golf Field" className="golf-rates-photo golf-field-photo"/> */}
              <img src={Golf3} alt="Golf Field" className="golf-rates-photo golf-field-photo"/>
              {/* <img src={Golf4} alt="Golf Field" className="golf-rates-photo golf-field-photo"/>
              <img src={Golf5} alt="Golf Field" className="golf-rates-photo golf-field-photo"/> */}
              <img src={SwimmingPool} alt="Swimming Pool" className="golf-rates-photo swimming-pool-photo"/>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>               
  );
}


