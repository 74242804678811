import './Announcements.css'
import React, { useState, useEffect } from "react"
import { Dropdown } from 'antd'
import { monthNames } from '../Helpers/Common.js'
import { getAllAnnouncements, getAllAnnouncementDates } from '../APICalls/AnnouncementAPI.js'
import Skeleton from '@mui/material/Skeleton'

import Navbar from "../components/Navbar.jsx"
import Footer from "../components/Footer.jsx"
import Event from '../components/Event.jsx'
// import Ellipse from "../assets/images/ellipse.png"
import ArrowDown from '../assets/icons/arrow-down.svg'
import AltaVistaLogo from '../assets/images/alta-vista2.png'


export default function Announcements() {
    const [isLoading, setIsLoading] = useState(false);
    const [announcements, setAnnouncements] = useState([]);
    const [currentMonthYear, setCurrentMonthYear] = useState('');
    const [selectedMonthYear, setSelectedMonthYear] = useState('');
    const [showFooter, setShowFooter] = useState(true);
    const [uniqueDates, setUniqueDates] = useState([]);

    useEffect(() => {
        const delay = 500;
        const timeoutId = setTimeout(() => {
          setShowFooter(true);
        }, delay);
    
        // Clean up the timeout to avoid memory leaks
        return () => clearTimeout(timeoutId);
    }, []);


    useEffect(() => {
        const fetchAnnouncements = async () => {
            setIsLoading(true);
            const response = await getAllAnnouncements();
            if (response && !response.error) {
                setAnnouncements(response);
            } else {
                console.error('Error fetching announcements:', response.error);
            }
            setIsLoading(false);
              
        };
        fetchAnnouncements();

        const fetchUniqueMonthYears = async () => {
            const response = await getAllAnnouncementDates();

            if (response && !response.error) {
                // Find the latest date in the response
                const latestDate = response.reduce((latest, date) => {
                    const [year1, month1, day1] = latest.split('-');
                    const [year2, month2, day2] = date.split('-');
                    return new Date(year1, month1 - 1, day1) > new Date(year2, month2 - 1, day2) ? latest : date;
                });
                const [year, month] = latestDate.split('-');

                setCurrentMonthYear(`${monthNames[parseInt(month) - 1]} ${year}`);
                const latestMonthYear = latestDate.substring(0, 7);
                setSelectedMonthYear(latestMonthYear);

                const uniqueDatesSet = new Set();
                response.forEach(date => {
                    const [year, month] = date.split('-');
                    uniqueDatesSet.add(`${year}-${month}`);
                });

                const uniqueDates = Array.from(uniqueDatesSet);
                setUniqueDates(uniqueDates);
            } else {
                console.error('Error fetching unique dates:', response.error);
            }
        };
        fetchUniqueMonthYears();
    }, []);

    const generateDropdownItems = (uniqueDates) => {
        // Extract unique yyyy-mm values
        const uniqueMonthYears = [...new Set(uniqueDates)];
    
        // Sort the unique yyyy-mm values
        const sortedUniqueMonthYears = uniqueMonthYears.sort((a, b) => {
            const [yearA, monthA] = a.split('-');
            const [yearB, monthB] = b.split('-');
            return new Date(yearB, monthB - 1) - new Date(yearA, monthA - 1);
        });
    
        // Map the unique values to dropdown items
        const dropdownItems = sortedUniqueMonthYears.map((date) => {
            const [year, month] = date.split('-');
            return {
                key: date,
                label: (
                    <a
                        className='admin-dropdown-content'
                        onClick={() => {
                            setCurrentMonthYear(`${monthNames[parseInt(month) - 1]} ${year}`);
                            setSelectedMonthYear(date);
                        }}
                    >
                        {`${monthNames[parseInt(month) - 1]} ${year}`}
                    </a>
                )
            };
        });
    
        return dropdownItems;
    };
    
    
    

    return(
        <div className='announcement-container'>
            <Navbar/>
            {isLoading && window.innerWidth > 1100 ? (
                <div className="skeleton">
                    <div className="announcement-title">
                        <Skeleton className='date-title' animation='wave' width={600} height={100} style={{ marginTop: -20 }}/>
                    </div>
                    {[...Array(3)].map((_, index) => (
                        <div className='skeleton-repeat' key={index}>
                            <div className="event-container">
                                <Skeleton animation='wave' variant='rectangular' width="35%" height={380} style={{ marginTop: -17 }} />
                                <div className='event-about-container'>
                                    <div className='event-about about-titles'>
                                        <Skeleton animation='wave' width={450} height={60} style={{ marginTop: -20, marginBottom: -15 }}/>
                                        <Skeleton animation='wave' width={450} height={45} />
                                        <Skeleton animation='wave' width={200} height={30} style={{ marginTop: -10, marginBottom: 15 }} />
                                    </div>

                                    <div className='event-about about-paragraph'>
                                        <Skeleton animation="wave" height={29} width={700} style={{ marginBottom: -6, marginTop: 10 }} />
                                        <Skeleton animation="wave" height={29} width={700} style={{ marginBottom: -6 }} />
                                        <Skeleton animation="wave" height={29} width={700} style={{ marginBottom: -6 }} />
                                        <Skeleton animation="wave" height={29} width="60%"/>
                                    </div>
                                </div>
                            </div>
                            <div className="line"></div>
                        </div>
                    ))}
                </div>
            ) : (
                <>
                    <div className='announcement-title'>
                        <h1 className='date-title'>{currentMonthYear} Announcements</h1>
                        <div className='button-down-container'>
                            <Dropdown
                                menu={{
                                    items: generateDropdownItems(uniqueDates),
                                }}
                                placement="bottom"
                                arrow={{
                                    pointAtCenter: true,
                                }}
                            >
                                <button className='date-button'>
                                    {currentMonthYear} <img src={ArrowDown} alt='Downward arrow' className='arrow-down' />
                                </button>
                            </Dropdown>
                        </div>
                    </div>
                    {announcements
                        .filter(announcement => announcement.date.startsWith(selectedMonthYear))
                        .sort((a, b) => new Date(b.date) - new Date(a.date))
                        .map((announcement) => (
                            <Event
                                key={announcement.id}
                                imgsrc={announcement.pictures.length > 0 ? announcement.pictures.map((pic) => `${process.env.REACT_APP_BASE_URL}assets/announcement_pictures/${announcement.id}/${pic.file_name}`) : [AltaVistaLogo]}
                                mainTitle={announcement.title}
                                secondTitle={announcement.subtitle}
                                eventDate={announcement.date}
                                paragraph={announcement.description}
                                isAnnouncement={true}
                            />
                        ))}
                     {showFooter && <Footer />}
                </>
            )}
        </div>
    );
}
