// import './Event.css';
// import React, { useEffect, useState } from 'react';
// import { Carousel, Button, Modal, DatePicker, Input } from 'antd';
// import { useNavigate } from 'react-router-dom';
// import { deleteAnnouncement, updateAnnouncement } from '../APICalls/AnnouncementAPI';
// import { refreshPage } from '../Helpers/Common';

// import ImageUploader from '../components/ImageUploader'

// import Clock from '../assets/icons/clock-icon.svg';
// import AltaVistaLogo from '../assets/images/alta-vista2.png';


// export default function Event(props){
//     const [loading, setLoading] = useState(false);
//     const [open, setOpen] = useState(false)
//     const [modalMainTitle, setModalMainTitle] = useState('');
//     const [modalSecondTitle, setModalSecondTitle] = useState('');
//     const [modalEventDate, setModalEventDate] = useState('');
//     const [modalParagrph, setModalParagraph] = useState('');
//     const [fileList, setFileList] = useState(
//         props.imgsrc[0] === AltaVistaLogo ? [] : props.imgsrc.map(image => ({ url: image }))
//     );
//     const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
//     const { TextArea } = Input;
//     const navigate = useNavigate(); 

//     useEffect(() => {
//         window.scrollTo(0, 0);
//     }, []);

//     const handleClick = () => {
//         // Extract the mainTitle, secondTitle, eventDate, and paragraph from props
//         const { mainTitle, secondTitle, eventDate, paragraph } = props;

//         // Create an array to hold all image URLs
//         const imgsrcArray = props.imgsrc.map(image => image);

//         // Navigate to Full-Story and pass the array of image URLs and other data
//         navigate('/Full-Story', {
//             state: {
//                 fstory_imgsrc: imgsrcArray,
//                 fstory_mainTitle: mainTitle,
//                 fstory_secondTitle: secondTitle,
//                 fstory_eventDate: eventDate,
//                 fstory_paragraph: paragraph
//             }
//         });
//     };

//     const truncateParagraph = (paragraph, limit) => {
//         if (paragraph.split(' ').length > limit) {
//             return paragraph.split(' ').slice(0, limit).join(' ') + '...';
//         }
//         return paragraph;
//     };

//     const showModal = () => {
//       setOpen(true);
//     };

//     const handleOk = () => {
//         setLoading(true);
//         setTimeout(() => {
//           setLoading(false);
//           setOpen(false);
//         }, 3000);
//     };

//     const handleDeleteConfirmation = () => {
//         setShowDeleteConfirmation(true);
//     };

//     const handleDeleteCancel = () => {
//         setShowDeleteConfirmation(false);
//     };

//     const handleDeleteConfirm = async () => {
//         const response = await deleteAnnouncement(props.announcementId);
//         setLoading(true);
//         setTimeout(() => {
//             setLoading(false);
//             setOpen(false);
//             setTimeout(() => {
//                 refreshPage();
//             }, 500);
//         }, 3000);
//         if (!response.error) {
//             console.log('Announcement deleted successfully');
//         } else {
//             console.error('Error deleting announcement:', response.error);
//         }
//         setShowDeleteConfirmation(false);
//     };

//     const handleSubmit = async () => {
//         const updatedData = {
//             title: modalMainTitle,
//             subtitle: modalSecondTitle,
//             date: modalEventDate,
//             description: modalParagrph,
//             file_name: fileList,
//         };
    
//         const response = await updateAnnouncement(props.announcementId, updatedData);
    
//         setLoading(true);
//         setTimeout(() => {
//             setLoading(false);
//             setOpen(false);
//             setTimeout(() => {
//                 refreshPage();
//             }, 500);
//         }, 3000);
    
//         if (!response.error) {
//             console.log('Announcement updated successfully');
//         } else {
//             console.error('Error updating announcement:', response.error);
//         }
//     };
  
//     const handleCancel = () => {
        

//         setOpen(false);
//     };

//     const handleEventClick = (mainTitle, secondTitle, eventDate, paragraph) => {
//         setModalMainTitle(mainTitle);
//         setModalSecondTitle(secondTitle);
//         setModalEventDate(eventDate);
//         setModalParagraph(paragraph);
//         showModal();
//     };

//     const formatDate = (dateString) => {
//         const options = { year: 'numeric', month: 'long', day: 'numeric' };
//         const date = new Date(dateString);
//         return date.toLocaleDateString('en-US', options);
//     };
    
//     return(
//         <div>
//         <div 
//             onClick={props.isAdminPage ? () => handleEventClick(props.mainTitle, props.secondTitle, props.eventDate, props.paragraph) : undefined} 
//             className={`event ${props.isAdminPage ? 'admin-event' : ''} ${props.isStoryPage ? 'story-event' : ''} ${props.isAnnouncement ? 'announcement-event' : ''}`}
//         >
//             <div className="event-container">
//                 {/* Display only the first image if isAdminPage or isAnnouncement is true */}
//                 {(props.isAdminPage || props.isAnnouncement) && (
//                     <img src={props.imgsrc[0]} alt='event' className='event-photo event-photo-1' />
//                 )}

//                 {/* Display all images in carousel if isStoryPage is true */}
//                 {(!props.isAdminPage && !props.isAnnouncement) && (
//                     <div className="full-story-carousel">
//                         <Carousel autoplay>
//                             {props.isStoryPage && (
//                                 props.imgsrc.map((image, index) => (
//                                     <div key={index}>
//                                         <img src={image} alt={`carousel item ${index}`} className={`carousel-image`} />
//                                     </div>
//                                 ))
//                             )}
//                         </Carousel>
//                     </div>
//                 )}

//                 <div className='event-about-container'>
//                     <div className='event-about about-titles'>
//                         <h1 className='event-main-title'>{props.mainTitle}</h1>
//                         <h3 className='event-second-title'>{props.secondTitle}</h3>
//                         <div className="event-date-container">
//                             <img src={Clock} alt='clock' className='clock-icon'/>
//                             <p className='event-date'>{formatDate(props.eventDate)}</p>
//                         </div>
//                     </div>

//                     <div className='event-about about-paragraph'>
//                         <p className='event-paragraph'>
//                             {(!props.isAdminPage && !props.isAnnouncement) ? props.paragraph : truncateParagraph(props.paragraph, 40)}
//                         </p>
                
//                         <button className="read-button" onClick={handleClick}>Read Full Story</button>
//                     </div>
//                 </div>
//             </div>
//             <div className="line"></div>
//         </div>
                
//         <Modal
//             open={open}
//             title="Edit Announcement"
//             onOk={handleOk}
//             onCancel={handleCancel}
//             maskClosable={false}
//             className='edit-modal'
//             footer={[
//             <Button danger key="delete" type="text" loading={loading} onClick={handleDeleteConfirmation}>
//                 <span className='modal-text-btn'>Delete</span>
//             </Button>,
//             <Button danger key="cancel" onClick={handleCancel}>
//                 <span className='modal-text-btn'>Cancel</span>
//             </Button>,
//             <Button key="usave" type="text" loading={loading} onClick={handleSubmit} style={{backgroundColor: 'var(--light-green)', color: 'white'}}>
//                 <span className='modal-text-btn'>Save Changes</span>
//             </Button>,
//             ]}
//         >
//             <p className='modal-input-label'>Title <span className='asterisk'>*</span></p>
//             <Input defaultValue={modalMainTitle} onChange={(e) => setModalMainTitle(e.target.value)} className='modal-input-box' />
//             <div className="modal-subtitle-date-container">
//                 <div className="modal-subtitle-container">
//                     <p className='modal-input-label'>Subtitle <span className='asterisk'>*</span></p>
//                     <Input defaultValue={modalSecondTitle} onChange={(e) => setModalSecondTitle(e.target.value)} className='modal-input-box modal-box-edit' />
//                 </div>
//                 <div className="modal-date-container">
//                     <p className='modal-input-label'>Date <span className='asterisk'>*</span></p>
//                     <DatePicker 
//                     // defaultValue={modalEventDate}
//                     placeholder={modalEventDate}
//                     getPopupContainer={(triggerNode) => {
//                         return triggerNode.parentNode;
//                     }}
//                     onChange={(date, dateString) => setModalEventDate(dateString)}
//                     className='date-picker'/>
//                 </div>
//             </div>
//             <p className='modal-input-label'>Description <span className='asterisk'>*</span></p>
//             <TextArea rows={4} autoSize={{ minRows: 4, maxRows: 11 }} className='modal-input-box modal-description-box' defaultValue={modalParagrph} onChange={(e) => setModalParagraph(e.target.value)}/>
//             <div className="image-uploader-container">
//                 <ImageUploader className='image-uploader-component' fileList={fileList} setFileList={setFileList}/>
//             </div>
//         </Modal>

//         <Modal
//             title="Delete Announcement"
//             open={showDeleteConfirmation}
//             onOk={handleDeleteConfirm}
//             onCancel={handleDeleteCancel}
//             okText="Yes"
//             cancelText="Cancel"
//             centered
//             footer={[
//                 <Button danger key="cancel" onClick={handleDeleteCancel}>
//                     <span className='modal-text-btn'>Cancel</span>
//                 </Button>,
//                 <Button danger key="delete" type="text" onClick={handleDeleteConfirm} style={{backgroundColor: 'red', color: 'white'}}>
//                     <span className='modal-text-btn'>Yes</span>
//                 </Button>,
//             ]}
//         >
//             <p style={{paddingBottom: '30px'}}>Are you sure you want to delete this announcement?</p>
//         </Modal>
//         </div>
        
//     );
// }



import './Event.css';
import React, { useEffect, useState } from 'react';
import { Carousel, Button, Modal, DatePicker, Input, Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import { deleteAnnouncement, updateAnnouncement } from '../APICalls/AnnouncementAPI';
import { refreshPage } from '../Helpers/Common';

import ImageUploader from '../components/ImageUploader'

import Clock from '../assets/icons/clock-icon.svg';
import AltaVistaLogo from '../assets/images/alta-vista2.png';


export default function Event(props){
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false)
    const [modalMainTitle, setModalMainTitle] = useState('');
    const [modalSecondTitle, setModalSecondTitle] = useState('');
    const [modalEventDate, setModalEventDate] = useState('');
    const [modalParagraph, setModalParagraph] = useState('');
    const [fileList, setFileList] = useState(
        props.imgsrc[0] === AltaVistaLogo ? [] : props.imgsrc.map(image => ({ url: image }))
    );
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [form] = Form.useForm();

    const { TextArea } = Input;
    const navigate = useNavigate(); 

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleClick = () => {
        // Extract the mainTitle, secondTitle, eventDate, and paragraph from props
        const { mainTitle, secondTitle, eventDate, paragraph } = props;

        // Create an array to hold all image URLs
        const imgsrcArray = props.imgsrc.map(image => image);

        // Navigate to Full-Story and pass the array of image URLs and other data
        navigate('/Full-Story', {
            state: {
                fstory_imgsrc: imgsrcArray,
                fstory_mainTitle: mainTitle,
                fstory_secondTitle: secondTitle,
                fstory_eventDate: eventDate,
                fstory_paragraph: paragraph
            }
        });
    };

    const truncateParagraph = (paragraph, limit) => {
        if (paragraph.split(' ').length > limit) {
            return paragraph.split(' ').slice(0, limit).join(' ') + '...';
        }
        return paragraph;
    };

    const showModal = () => {
      setOpen(true);
    };

    const handleOk = () => {
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
          setOpen(false);
        }, 3000);
    };

    const handleDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    };

    const handleDeleteCancel = () => {
        setShowDeleteConfirmation(false);
    };

    const handleDeleteConfirm = async () => {
        const response = await deleteAnnouncement(props.announcementId);
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setOpen(false);
            setTimeout(() => {
                refreshPage();
            }, 500);
        }, 3000);
        if (!response.error) {
            console.log('Announcement deleted successfully');
        } else {
            console.error('Error deleting announcement:', response.error);
        }
        setShowDeleteConfirmation(false);
    };

    const handleSubmit = async () => {
        form.validateFields()
        .then(async (values) => {
            const updatedData = {
                title: values.modalMainTitle,
                subtitle: values.modalSecondTitle,
                date: values.modalEventDate ? values.modalEventDate.format('YYYY-MM-DD') : null,
                description: values.modalParagraph,
                file_name: fileList,
            };

            const response = await updateAnnouncement(props.announcementId, updatedData);

            setLoading(true);
            setTimeout(() => {
                setLoading(false);
                setOpen(false);
                setTimeout(() => {
                    refreshPage();
                }, 500);
            }, 3000);

            if (!response.error) {
                console.log('Announcement updated successfully');
            } else {
                console.error('Error updating announcement:', response.error);
            }
        })
        .catch(errorInfo => {
            console.log('Failed:', errorInfo);
        });
    };
  
    const handleCancel = () => {
        form.resetFields();
        setOpen(false);
    };

    const handleEventClick = (mainTitle, secondTitle, eventDate, paragraph) => {
        setModalMainTitle(mainTitle);
        setModalSecondTitle(secondTitle);
        setModalEventDate(eventDate);
        setModalParagraph(paragraph);
        showModal();
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', options);
    };
    
    return(
        <div>
        <div 
            onClick={props.isAdminPage ? () => handleEventClick(props.mainTitle, props.secondTitle, props.eventDate, props.paragraph) : undefined} 
            className={`event ${props.isAdminPage ? 'admin-event' : ''} ${props.isStoryPage ? 'story-event' : ''} ${props.isAnnouncement ? 'announcement-event' : ''}`}
        >
            <div className="event-container">
                {/* Display only the first image if isAdminPage or isAnnouncement is true */}
                {(props.isAdminPage || props.isAnnouncement) && (
                    <img src={props.imgsrc[0]} alt='event' className='event-photo event-photo-1' />
                )}

                {/* Display all images in carousel if isStoryPage is true */}
                {(!props.isAdminPage && !props.isAnnouncement) && (
                    <div className="full-story-carousel">
                        <Carousel autoplay>
                            {props.isStoryPage && (
                                props.imgsrc.map((image, index) => (
                                    <div key={index}>
                                        <img src={image} alt={`carousel item ${index}`} className={`carousel-image`} />
                                    </div>
                                ))
                            )}
                        </Carousel>
                    </div>
                )}

                <div className='event-about-container'>
                    <div className='event-about about-titles'>
                        <h1 className='event-main-title'>{props.mainTitle}</h1>
                        <h3 className='event-second-title'>{props.secondTitle}</h3>
                        <div className="event-date-container">
                            <img src={Clock} alt='clock' className='clock-icon'/>
                            <p className='event-date'>{formatDate(props.eventDate)}</p>
                        </div>
                    </div>

                    <div className='event-about about-paragraph'>
                        <p className='event-paragraph'>
                            {(!props.isAdminPage && !props.isAnnouncement) ? props.paragraph : truncateParagraph(props.paragraph, 40)}
                        </p>
                
                        <button className="read-button" onClick={handleClick}>Read Full Story</button>
                    </div>
                </div>
            </div>
            <div className="line"></div>
        </div>
                
        <Modal
            open={open}
            title="Edit Announcement"
            onOk={handleOk}
            onCancel={handleCancel}
            maskClosable={false}
            className='edit-modal'
            footer={[
            <Button danger key="delete" type="text" loading={loading} onClick={handleDeleteConfirmation}>
                <span className='modal-text-btn'>Delete</span>
            </Button>,
            <Button danger key="cancel" onClick={handleCancel}>
                <span className='modal-text-btn'>Cancel</span>
            </Button>,
            <Button key="usave" type="text" loading={loading} onClick={handleSubmit} style={{backgroundColor: 'var(--light-green)', color: 'white'}}>
                <span className='modal-text-btn'>Save Changes</span>
            </Button>,
            ]}
        >
            <Form form={form} onFinish={handleSubmit} layout="vertical" initialValues={{modalMainTitle, modalSecondTitle, modalParagraph}}>
                <Form.Item
                    label="Title"
                    name="modalMainTitle"
                    value={modalMainTitle}
                    rules={[{ required: true, message: 'Please input the title!' }]}
                >
                    <Input  
                        onChange={(e) => setModalMainTitle(e.target.value)} 
                        className='modal-input-box' 
                    />
                </Form.Item>
                <div className="modal-subtitle-date-container">
                    <div className="modal-subtitle-container">
                    <Form.Item
                        label="Subtitle"
                        name="modalSecondTitle"
                        rules={[{ required: true, message: 'Please input the subtitle!' }]}
                    >
                        <Input 
                            onChange={(e) => setModalSecondTitle(e.target.value)} 
                            className='modal-input-box modal-box-edit' 
                        />
                    </Form.Item>
                    </div>
                    <div className="modal-date-container">
                    <Form.Item
                        label="Date"
                        name="modalEventDate"
                        rules={[{ required: true, message: 'Please select a date!' }]}
                    >
                        <DatePicker 
                            placeholder={modalEventDate}
                            getPopupContainer={(triggerNode) => {
                                return triggerNode.parentNode;
                            }}
                            onChange={(date, dateString) => setModalEventDate(dateString)}
                            className='date-picker'
                        />
                    </Form.Item>
                    </div>
                </div>
                <Form.Item
                    hasFeedback
                    validateDebounce={2000}
                    label="Description"
                    name="modalParagraph"
                    rules={[{ required: true, message: 'Description is required!' }, { min: 200, message: 'Description must have 25 words or more' }]}
                >
                    <TextArea 
                        rows={4} 
                        autoSize={{ minRows: 4, maxRows: 8 }} 
                        className='modal-input-box modal-description-box' 
                        onChange={(e) => setModalParagraph(e.target.value)}
                    />
                </Form.Item>
                <Form.Item
                    label="Images"
                    name="images"
                    rules={[{ required: false, message: 'Please upload images!' }]}
                >
                    <div className="image-uploader-container">
                        <ImageUploader className='image-uploader-component' fileList={fileList} setFileList={setFileList}/>
                    </div>
                </Form.Item>
            </Form>
        </Modal>

        <Modal
            title="Delete Announcement"
            open={showDeleteConfirmation}
            onOk={handleDeleteConfirm}
            onCancel={handleDeleteCancel}
            okText="Yes"
            cancelText="Cancel"
            centered
            footer={[
                <Button danger key="cancel" onClick={handleDeleteCancel}>
                    <span className='modal-text-btn'>Cancel</span>
                </Button>,
                <Button danger key="delete" type="text" onClick={handleDeleteConfirm} style={{backgroundColor: 'red', color: 'white'}}>
                    <span className='modal-text-btn'>Yes</span>
                </Button>,
            ]}
        >
            <p style={{paddingBottom: '30px'}}>Are you sure you want to delete this announcement?</p>
        </Modal>
        </div>
        
    );
}

