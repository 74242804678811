import './AdminLoginPage.css'
import React, { useState } from 'react'
import { login }from '../APICalls/AuthAPI.js'
import { toast } from "react-hot-toast"
import { Input } from 'antd';

import Navbar from "../components/Navbar.jsx"
import Footer from "../components/Footer.jsx"

import Background from "../assets/images/login-background.png"

export default function LoginPage() {
    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const onFinish = async () => {
        setIsLoading(true);
        const values = { username, password };
        const response = await login(values);
        if (response.data){
            localStorage.setItem('user_id', response.data.user_id);
            localStorage.setItem('role_id', response.data.role_id);
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('token_expiry', response.data.token_expiry);

            toast.success("Login successfully!");
            setTimeout(() => {
                window.location.href = '/Admin';
            }, 1700);
        } else {
            toast.error(response.error.messages.error_messages.message);
        }
        setIsLoading(false);
    };

    return(
        <div className="login-page-container">
            <Navbar/>
            <img src={Background} alt="Background" className='adlogin-background-image'/>
            <div className="adlogin-form-container">
                <div className="adlogin-container">
                    <h3 className="adlogin-title">Admin Login</h3>
                    <p className='adlogin-input-label'>Username</p>
                    <Input 
                        className='adlogin-input-box'
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}/>
                    <p className='adlogin-input-label'>Password</p>
                    <Input.Password
                        className='adlogin-input-box'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}/>
                    <div className='adlogin-enter-button'>
                        <button className='adlogin-button' onClick={onFinish}>Login</button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}