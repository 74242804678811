import './AddModal.css'
import React, { useState } from 'react';
import { Button, Modal, DatePicker, Input, Form } from 'antd';
import { createAnnouncement } from '../APICalls/AnnouncementAPI';
import { refreshPage } from '../Helpers/Common';

import ImageUploader from './ImageUploader';

function AddModal() {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false)
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [date, setDate] = useState(null);
  const [description, setDescription] = useState('');
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();

  const { TextArea } = Input;

  const showModal = () => {
    setOpen(true);
  };

  const handleSubmit = () => {
    form.validateFields()
        .then(values => {
            setLoading(true);
            const data = {
                title: values.title,
                subtitle: values.subtitle,
                date: values.date ? values.date.format('YYYY-MM-DD') : null,
                description: values.description,
                file_name: fileList,
            };

            // Call createAnnouncement with the data
            createAnnouncement(data)
                .then(() => {
                    setTimeout(() => {
                        setLoading(false);
                        setOpen(false);
                        setTimeout(() => {
                            refreshPage();
                        }, 500);
                    }, 3000);
                })
                .catch(error => {
                    setLoading(false);
                    console.error('Create announcement error:', error);
                });
        })
        .catch(errorInfo => {
            console.log('Failed:', errorInfo);
        });
  };
  
  const handleCancel = () => {
    setTitle('');
    setSubtitle('');
    setDate(null);
    setDescription('');
    setFileList([]);
    form.resetFields();
    setOpen(false);
  };
  
  const onChange = (date, dateString) => {
    setDate(date);
  };

  const handleEventClick = () => {
    showModal();
  };

  return (
    <>
      <button onClick={handleEventClick} className="admin-add-button">Add</button>
      <Modal
        open={open}
        title="Create an Announcement"
        onOk={handleSubmit}
        onCancel={handleCancel}
        maskClosable={false}
        className='edit-modal'
        footer={[
          <Button danger key="cancel" onClick={handleCancel}>
            <span className='modal-text-btn'>Cancel</span>
          </Button>,
          <Button key="usave" type="text" loading={loading} onClick={handleSubmit} style={{backgroundColor: 'var(--light-green)', color: 'white'}}>
            <span className='modal-text-btn'>Submit</span>
          </Button>,
        ]}
      >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: 'Title is required!' }]}
          >
            <Input className='modal-input-box' value={title} onChange={e => setTitle(e.target.value)}/>
          </Form.Item>
          <div className="modal-subtitle-date-container">
            <div className="modal-subtitle-container">
              <Form.Item
                label="Subtitle"
                name="subtitle"
                rules={[{ required: true, message: 'Subtitle is required!' }]}
              >
                <Input className='modal-input-box modal-box-edit' value={subtitle} onChange={e => setSubtitle(e.target.value)}  />
              </Form.Item>
            </div>
            <div className="modal-date-container">
              <Form.Item
                label="Date"
                name="date"
                rules={[{ required: true, message: 'Please select a date!' }]}
              >
                <DatePicker 
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  value={date}
                  onChange={onChange} 
                  className='date-picker modal-dp'
                />
              </Form.Item>
            </div>
          </div>
          <Form.Item
            hasFeedback
            validateDebounce={2000}
            label="Description"
            name="description"
            rules={[{ required: true, message: 'Description is required!' }, { min: 200, message: 'Description must have 25 words or more' }]}
          >
            <TextArea 
              rows={4} 
              autoSize={{ minRows: 4, maxRows: 8 }} 
              className='modal-input-box modal-description-box' 
              value={description} onChange={e => setDescription(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label="Images"
            name="images"
            rules={[{ required: false, message: 'Upload atleast one image' }]}
          >
            <div className="image-uploader-container">
              <ImageUploader className='image-uploader-component' fileList={fileList} setFileList={setFileList}/>
            </div>
          </Form.Item>
        </Form>

      </Modal>
    </>
  );
}

export default AddModal;
