export function refreshPage() {
    window.location.reload();
}

// Return user id from local storage
export const getUserId = () => {
    return localStorage.getItem("user_id");
};

//return role id from local storage
export const getRoleId = () => {
    return localStorage.getItem("role_id") || null;
};

// Return token from local storage
export const getToken = () => {
    return localStorage.getItem("token");
};

//return token expiry from local storage
export const getTokenExpiry = () => {
    return localStorage.getItem("token_expiry") || null;
};

//remove token from local storage
export const removeSession = () => {
    localStorage.removeItem("user_id");
    localStorage.removeItem("role_id");
    localStorage.removeItem("token");
    localStorage.removeItem("token_expiry");
};

export const monthNames = [
    "January", 
    "February", 
    "March", 
    "April", 
    "May", 
    "June",
    "July", 
    "August", 
    "September", 
    "October", 
    "November", 
    "December"
];