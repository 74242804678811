import { getToken, getUserId, removeSession, refreshPage } from "../Helpers/Common";
import { postAPICall, getAPICall } from "./AxiosMethodCalls";


/***************************
 * ANNOUNCEMENT
 ***************************/

const token = getToken();
const user = getUserId();

export const createAnnouncement = async (data) => {
  try {
      const formData = new FormData();
      formData.append('token', token);
      formData.append('requester', user);
      formData.append('title', data.title);
      formData.append('subtitle', data.subtitle);
      formData.append('date', data.date);
      formData.append('description', data.description);
      
      // Append each file to the formData
      data.file_name.forEach((file, index) => {
          formData.append(`file_name[${index}]`, file.originFileObj);
      });

      const response = await postAPICall(process.env.REACT_APP_BASE_URL + "announcements/create", formData, {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
      }); 
      return { data: response };   
  } catch (error) {
      if (error.response.status === 401) {
          removeSession();
          refreshPage();
      }
      return { error: error.response };
  }
}


export const getAllAnnouncements = async () => {
  try {
    const response = await getAPICall(process.env.REACT_APP_BASE_URL + "announcements/get", {
      headers: {
        "Content-Type": 'multipart/form-data',
      },
      params: {
        requester: user,
        token: token,
      }
    });
    return response.data.data; // Return the data array directly
  } catch (error) {
    if (error.response.status === 401) {
      removeSession();
      refreshPage();
    }
    return { error: error.response };
  }
};


export const updateAnnouncement = async (id, data) => {
  try {
      const formData = new FormData();
      formData.append('token', token);
      formData.append('requester', user);
      formData.append('announcement_id', id);
      formData.append('title', data.title);
      formData.append('subtitle', data.subtitle);
      formData.append('date', data.date);
      formData.append('description', data.description);
      
      // Append each file to the formData
      data.file_name.forEach((file, index) => {
          formData.append(`file_name[${index}]`, file.originFileObj);
      });

      const response = await postAPICall(process.env.REACT_APP_BASE_URL + "announcements/update", formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
      }); 
      return ({data:response});   
    } catch (error) {
      return ({error: error.response});
  }
}


export const deleteAnnouncement = async (id) => {
  try {
      const response = await postAPICall(process.env.REACT_APP_BASE_URL + "announcements/delete", {
          requester: user,
          token: token,
          announcement_id: id,
      });
      return ({data:response});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const getAllAnnouncementDates = async () => {
  try {
    const response = await getAPICall(process.env.REACT_APP_BASE_URL + "announcements/get", {
      headers: {
        "Content-Type": 'multipart/form-data',
      },
      params: {
        requester: user,
        token: token,
      }
    });
    const dates = response.data.data.map(announcement => announcement.date); // Extract dates from data array
    return dates;
  } catch (error) {
    if (error.response.status === 401) {
      removeSession();
      refreshPage();
    }
    return { error: error.response };
  }
};
