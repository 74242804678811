import './Navbar.css';
import React, { useState }  from "react";
import { useNavigate, Link } from "react-router-dom"

import Logo from '../assets/images/alta-vista.png';
import LocationIcon from '../assets/icons/location-icon.svg';
import PhoneIcon from '../assets/icons/phone-icon.svg';
import MenuIcon from '../assets/icons/menu-icon.svg'


export default function Navbar() {
  
  const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
  };

  const navigate = useNavigate()

  const handleNagivate = (route) => {
    navigate(route)
  }

  return (
    <nav>
      <div className="navbar-top">
        <div className="navbar-top-left">
          <img
              src={Logo}
              alt="Logo"
              className="logo"
              onClick={() => handleNagivate("/")}
            />
          <div className="title-container">
            <h1 className="title-1">ALTA VISTA</h1>
            <h5 className="title-2">Golf & Country Club</h5>
          </div>
        </div>
        <div className="navbar-top-right">
          <img src={PhoneIcon} alt="phone icon" className="phone-icon-1"/>
          <a href='tel:+639173015422' className='phone-link'>
            <div className="phone-container">
              <h4 className="phone-number">+632 263 5533-35</h4>
              <p className="trunkline">Landline</p>
            </div>
          </a>
          <img src={LocationIcon} alt="location icon" className="location-icon-1"/>
          <a href='https://www.google.com/maps/search/Pardo+Cebu+City,+Cebu' class='location-link'>
            <div className="location-container">
              <h4 className="address-1">Pardo Hills</h4>
              <p className="address-2">Pardo, Cebu City</p>
            </div>
          </a>
          
          <div className='navbar-dropdown-container'>
            <button onClick={toggleDropdown} className='navbar-dropdown-button'>
              <img src={MenuIcon} alt="Navbar" className="navbar-icon"/>
            </button>
            {isOpen && (
              <ul className='navbar-dropdown-content'>
                <li><a href="/">Home</a></li>
                <li><a href="/Announcements">Announcements</a></li>
                <li><a href="/Facilities">Facilities</a></li>
                <li><a href="/Member-Login">Login</a></li>
              </ul>
            )}
          </div>
        </div>
      </div>
      
      <div className="navbar-bottom">
        <div className="navbar-bot-left">
          <a href="/" className="nav-list home">Home</a>
          <a href="/Announcements" className="nav-list announcements">Announcements</a>
          <a href="/Facilities" className="nav-list facilities">Facilities</a>
        </div>
        <div className="navbar-bot-right">
          <Link to="/Member-Login">
            <button className="login-button">Login</button>
          </Link>
        </div>
      </div>
    </nav>
  )
}
