import './Form.css'
import React from 'react';
import Logo from "../assets/images/alta-vista.png"
import { Input } from 'antd';

export default function RegisterForm({onToggleForm}){

    return(
        <div className="form-container">
            <div className="title-logo-container">
                <div className="login-title">
                    <h1 className='login-title-1'>Welcome to Alta Vista!</h1>
                    <p className='login-title-2'>Create an Account</p>
                </div>
                <img src={Logo} alt='Logo' className='alta-logo'/>
            </div>
            <div className="input-entries">
                <div className="register-entry">
                    <div className="register-entry-1">
                        <p className='input-label'>First Name</p>
                        <Input className='input-box-half'/>
                    </div>
                    <div className="register-entry-2">
                        <p className='input-label'>Last Name</p>
                        <Input className='input-box-half'/>
                    </div>
                </div>
                <div className="register-entry">
                    <div className="register-entry-1">
                        <p className='input-label'>Email</p>
                        <Input className='input-box-half'/>
                    </div>
                    <div className="register-entry-2">
                        <p className='input-label'>Phone</p>
                        <Input className='input-box-half'/>  
                    </div>
                </div>
                <p className='input-label'>Address</p>
                <Input className='input-box'/>
                <p className='input-label'>Password</p>
                <Input className='input-box'/>

            </div>
            <div className="account-question-container register-container">
                <p>Already have an account?</p>
                <p><a href="#Login" onClick={onToggleForm} className='form-button'>Login</a></p>
            </div>
        </div>
    );
}
