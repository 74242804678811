import axios from "axios";
import qs from 'qs';


const postCall = async (url, data, config) => {
    try {
        const response = await axios.post(process.env.REACT_APP_BASE_URL+url, qs.stringify(data), config);
        return response.data;
    } catch (error) {
        throw error.response;
    }
};


/***************************
 * LOGIN
 ***************************/
export const login = async (values) => {
    try {
        const response = await postCall(
            'login',
            { ...values },
            {
                headers: {
                    'api-key': process.env.REACT_APP_API_KEY,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
        );
        return { data: response };
    } catch (error) {
        return { error: error.data };
    }
};


/***************************
 * LOGOUT
 ***************************/
export const logout = async (token, requester) => {
    try {
        const response = await postCall(
            'logout',
            { token, requester},
            {
                headers: {
                    'api-key': process.env.REACT_APP_API_KEY,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
        );
        return { data: response };
    } catch (error) {
        return { error: error.data };
    }
};