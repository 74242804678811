import './Homepage.css'
import React from "react"

import Navbar from "../components/Navbar"
import Hero from "../components/Hero"
import Footer from "../components/Footer"

import ellipse from "../assets/images/ellipse.png"
import blackBackground from "../assets/images/black-background.png"
import ObjectiveIcon from "../assets/icons/mission-icon.svg"
import Golf2 from '../assets/images/golf-2.png'
import Golf4 from '../assets/images/golf-4.png'
import Golf5 from '../assets/images/golf-5.png'

export default function Homepage() {
  return (
    <div className="homepage-container">
      <Navbar/>
      <Hero/>
      <div className="home-description">
        <img src={ellipse} alt="ellipse shape" className="ellipse-1" />
        <h3 className="home-about">ABOUT THE CLUB</h3>
        <h1 className="home-title">Alta Vista Golf & Country Club</h1>
        <div className='home-pictures-container'>
          <img src={Golf2} alt="Golf Field" className="home-golf-img"/>
          <img src={Golf5} alt="Golf Field" className="home-golf-img"/>
          <img src={Golf4} alt="Golf Field" className="home-golf-img"/>
        </div>
        <p className="home-paragraph p1">The Club was founded in 1994 through the joint venture efforts of the Philippines’ leading golf and real estate developer, STA. LUCIA REALTY & DEVELOPMENT INC., in partnership with one of the Cebu’s oldest families, the AZNAR’s of Southwestern University.</p>
        <p className="home-paragraph p2">Nestled some 500 feet above the nearby beaches and located just 5 miles from the heart of the City, the Club occupies 60 hectares of a 400-hectare residential cum leisure development that places Cebu in the roster of world exclusive club destination. GARY PLAYER’s Black Knight Design Group of South Africa was commissioned to design a championship layout that enhances the challenge and beauty of the game of golf. The resultant 6,237 yard par-72 course has become not just one of the more popular venues for tournament championship, but boasts of the most picturesque holes in Philippine golf. From practically every tee, fairway and green, one can enjoy the breathtaking view of the Queen City of the South and its surrounding tropical islands.</p>
        <p className="home-paragraph p3">The Spanish Colonial – inspired Clubhouse is another masterpiece that has now become the imposing landmark of the fabled Pardo Hill of Cebu. Visible even from across the Mactan Strait, this functionally – designed clubhouse is designed by Manila – based W. V. COSCOLLUELA and Associates and was completed in May 1997. With more than 14,000 square meters of floor space, the Golf Wing consists of the Reception Desk, Golfer’s Lounge; air conditioned Men’s and Ladies Lockers, Golfers Bar and a Pro Shop. The banquet facilities include a 500 sits Grand Ballroom, a Family Restaurant, a Fine Dining Restaurant, the Bowling Café and a Pool Deck Gazebo.  Ample parking is available to all members, their family and guests at the Landscaped Courtyard and the 3-level basement parking. The architect’s Interior Design partners, SONGSONG & PERIQUET, completes the finishing touches with custom  designed furniture liberally interspersed with period pieces from old Spanish era churches and centuries old villas.</p>
        <p className="home-paragraph p4">A round of golf at Alta Vista is always a rewarding experience. The challenges of the game are rewarded by the beauty of its environment, state of the art amenities and the impeccable service of its well-trained staff. Alta Vista’s Member and Guests deserve nothing less.</p>
        <img src={ellipse} alt="ellipse shape" className="ellipse-2" />
      </div>
      <div className="objectives-purposes-container">
        <img src={blackBackground} alt='black background' className="black-bg-2"/>
        <div className="obj-prps-container">
          <div className="object-container">
            <img src={ObjectiveIcon} alt='objectives icon' className="mission-icon"/>
            <div className="objectives-and-purposes">
              <h3 className="obj-title">OBJECTIVES & PURPOSES</h3>
              <p className="obj-sentence">The Club has been created to establish the following objectives and purposes:</p>
              <div className="obj-purposes">
                <ol className="obj-prps-list">
                  <li>To provide and maintain exceptional facilities for Club activities.</li>
                  <li>To encourage the use and enjoyment of the Club Facilities and Activities by its members, their family and guests and in so doing to contribute and enhance the quality of their lives.</li>
                  <li>To provide exceptional levels of personal attention and service to its members, their family and guests.</li>
                  <li>To serve as a center of recreation, entertainment, sporting and social activities for its members, their family, and guests.</li>
                  <li>To conduct both casual and planned programs of recreation, entertainment, sporting and social activities for individuals of all ages.</li>
                  <li>To create and maintain a unique, pleasant, and attractive physical environment in complete harmony with the neighboring community.</li>
                </ol>
                <ol start="7" className="obj-prps-list">
                  <li>To promote and enhance the various sports, conducted by the Club and their principles, values and traditions and in particular to promote, encourage, teach and instill to all members, their family and guests the principles of sportsmanship etiquette, fair play and consideration for others.</li>
                  <li>To seek and retain as members of the Club a congenial and harmonious group of individuals and families of exceptional qualities and character to share as a common goal the accomplishment of the objectives and purposes of the Club in a spirit and atmosphere of camaraderie and friendship.</li>
                  <li>To levy and collect fees from members to enable the Club to carry out these objectives and purposes.</li>
                  <li>To do such other things as the Club consider appropriate to perform the functions of the Club and to carry out the objectives of the Club.</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}
