import './Form.css'
import React from "react"
import Logo from "../assets/images/alta-vista.png"
import { Input } from 'antd';

export default function LoginForm({onToggleForm}){

    return(
        <div className="form-container">
            <div className="title-logo-container">
                <div className="login-title">
                    <h1 className='login-title-1'>Welcome Back!</h1>
                    <p className='login-title-2'>Sign in</p>
                </div>
                <img src={Logo} alt='Logo' className='alta-logo'/>
            </div>
            <div className="input-entries">
                <p className='input-label'>Email</p>
                <Input className='input-box'/>
                <p className='input-label'>Password</p>
                <Input.Password className='input-box'/>
            </div>
            <div className="account-question-container">
                <p>No account yet?</p>
                <p><a href="#Register" onClick={onToggleForm} className='form-button'>Register</a></p>
            </div>
        </div>
    );
}
