import axios from "axios";

const config = {
  headers: {
    // "api-key": process.env.REACT_APP_API_KEY,
    "Content-Type": 'multipart/form-data',
  },
};

// API Axios Get Call.
export const getAPICall = (url, data) => {
  return axios.get(url, data, config);
};

// API Axios Post Call.
export const postAPICall = (url, data) => {
  return axios.post(url, data, config);
};
