import "./App.css"

import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { Toaster } from "react-hot-toast"
import { getToken } from "./Helpers/Common.js"

import Homepage from "./pages/Homepage.jsx"
import Announcements from "./pages/Announcements.jsx"
import LoginPage from "./pages/LoginPage.jsx"
import AdminPage from "./pages/AdminPage.jsx"
import AdminLoginPage from "./pages/AdminLoginPage.jsx"
import Facilities from "./pages/Facilities.jsx"
import StoryPage from "./pages/StoryPage.jsx"

function App() {
  const userToken = getToken();

  return (
    <div className="App">
      <Toaster/>
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/Announcements" element={<Announcements />} />
          <Route path="/Facilities" element={<Facilities />} />
          <Route path="/Member-Login" element={<LoginPage />} />
          <Route path="/Admin" element={userToken ? <AdminPage /> : <AdminLoginPage/>} />
          <Route path="/Admin-Login" element={<AdminLoginPage />} />
          <Route path="/Full-Story" element={<StoryPage />} />
        </Routes>
      </Router>
    </div>
  )
}

export default App
