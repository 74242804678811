import './AdminPage.css'
import React, { useState, useEffect } from 'react';
import { Dropdown } from 'antd'
import { logout } from '../APICalls/AuthAPI.js'
import { getUserId, getToken, removeSession, monthNames } from '../Helpers/Common.js'
import { getAllAnnouncements, getAllAnnouncementDates } from '../APICalls/AnnouncementAPI.js';

import Event from '../components/Event'
import AddModal from '../components/AddModal'
import ArrowDown from '../assets/icons/arrow-down.svg'
import AltaVistaLogo from '../assets/images/alta-vista2.png'

export default function AdminPage () {
    const [isLoading, setIsLoading] = useState(false);
    const [announcements, setAnnouncements] = useState([]);
    const [currentMonthYear, setCurrentMonthYear] = useState('');
    const [selectedMonthYear, setSelectedMonthYear] = useState('');
    const [uniqueDates, setUniqueDates] = useState([]);
    const token = getToken();
    const requester = getUserId();

    useEffect(() => {
        const fetchAnnouncements = async () => {
            setIsLoading(true);
            const response = await getAllAnnouncements();
            if (response && !response.error) {
                setAnnouncements(response);
            } else {
                console.error('Error fetching announcements:', response.error);
            }
            setIsLoading(false);
        };
        fetchAnnouncements();

        
        
        const fetchUniqueMonthYears = async () => {
            const response = await getAllAnnouncementDates();

            if (response && !response.error) {
                // Find the latest date in the response
                const latestDate = response.reduce((latest, date) => {
                    const [year1, month1, day1] = latest.split('-');
                    const [year2, month2, day2] = date.split('-');
                    return new Date(year1, month1 - 1, day1) > new Date(year2, month2 - 1, day2) ? latest : date;
                });
                const [year, month] = latestDate.split('-');

                setCurrentMonthYear(`${monthNames[parseInt(month) - 1]} ${year}`);
                const latestMonthYear = latestDate.substring(0, 7);
                setSelectedMonthYear(latestMonthYear);

                const uniqueDatesSet = new Set();
                response.forEach(date => {
                    const [year, month] = date.split('-');
                    uniqueDatesSet.add(`${year}-${month}`);
                });

                const uniqueDates = Array.from(uniqueDatesSet);
                setUniqueDates(uniqueDates);
            } else {
                console.error('Error fetching unique dates:', response.error);
            }
        };
        fetchUniqueMonthYears();
    }, []);

    const generateDropdownItems = (uniqueDates) => {
        // Extract unique yyyy-mm values
        const uniqueMonthYears = [...new Set(uniqueDates)];
    
        // Sort the unique yyyy-mm values
        const sortedUniqueMonthYears = uniqueMonthYears.sort((a, b) => {
            const [yearA, monthA] = a.split('-');
            const [yearB, monthB] = b.split('-');
            return new Date(yearB, monthB - 1) - new Date(yearA, monthA - 1);
        });
    
        // Map the unique values to dropdown items
        const dropdownItems = sortedUniqueMonthYears.map((date) => {
            const [year, month] = date.split('-');
            return {
                key: date,
                label: (
                    <a
                        className='admin-dropdown-content'
                        onClick={() => {
                            setCurrentMonthYear(`${monthNames[parseInt(month) - 1]} ${year}`);
                            setSelectedMonthYear(date);
                        }}
                    >
                        {`${monthNames[parseInt(month) - 1]} ${year}`}
                    </a>
                )
            };
        });
    
        return dropdownItems;
    };

    const handleLogout = async () => {
        setIsLoading(true);
        if (token && requester) {
            const response = await logout( token, requester);
            if (response.data) {
                window.location.href = '/';
                removeSession();
            } else {
                console.error('Logout error:', response.error);
            }
        } else {
            console.error('Token or requester missing.');
        }
        setIsLoading(false);
    };

    return (
        <div className='admin-container'>
            <div className="admin-container-2">
                <div className="admin-announcement-header">
                    <h1 className='admin-announcement-title'>Announcements</h1>
                    <button className="logoff-button" onClick={handleLogout}>Logoff</button>
                </div>
                <div className="admin-buttons-container">
                    <Dropdown
                        menu={{
                            items: generateDropdownItems(uniqueDates),
                        }}
                        placement="bottom"
                        arrow={{
                            pointAtCenter: true,
                        }}
                    >
                        <button className='date-button'>
                            {currentMonthYear} <img src={ArrowDown} alt='Downward arrow' className='arrow-down' />
                        </button>
                    </Dropdown>
                    <AddModal />
                </div>
                <div className="admin-announcements">
                {announcements
                    .filter(announcement => announcement.date.startsWith(selectedMonthYear))
                    .sort((a, b) => new Date(b.date) - new Date(a.date))
                    .map((announcement) => (
                        <Event
                            key={announcement.id}
                            announcementId={announcement.id}
                            imgsrc={announcement.pictures.length > 0 ? announcement.pictures.map((pic) => `${process.env.REACT_APP_BASE_URL}assets/announcement_pictures/${announcement.id}/${pic.file_name}`) : [AltaVistaLogo]}
                            mainTitle={announcement.title}
                            secondTitle={announcement.subtitle}
                            eventDate={announcement.date}
                            paragraph={announcement.description}
                            isAdminPage={true}
                        />
                    ))}
                </div>
                {/* <div className="admin-nav-buttons">
                        <button className="admin-button admin-previous-button">&lt;&lt; Previous</button>
                        <button className="admin-button admin-next-button">Next &gt;&gt;</button>
                </div> */}
            </div>
        </div>
    );
}