import React, { useState } from 'react';
import { Image, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';


export default function ImageUploader({ fileList, setFileList }) {
  const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChange = ({ fileList: newFileList }) => {
    // Convert URL to File object
    const convertUrlToFile = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        return new File([blob], 'image.jpg', { type: blob.type });
    };

    // Map fileList to convert URLs to File objects
    Promise.all(
        newFileList.map(async (file) => {
            if (!file.originFileObj && file.url) {
                return convertUrlToFile(file.url);
            }
            return file.originFileObj;
        })
    ).then((fileObjects) => {
        // Update fileList with converted File objects
        setFileList(fileObjects.map((file, index) => ({
            uid: newFileList[index].uid,
            name: file.name,
            status: 'done',
            url: URL.createObjectURL(file),
            originFileObj: file,
        })));
    });
};

  
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );


  return (
    <>
      <Upload
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        beforeUpload={() => false} 
        accept=".jpg,.jpeg,.png"
      >
        {fileList.length >= 8 ? null : uploadButton}
      </Upload>
      {previewImage && (
        <Image
          wrapperStyle={{
            display: 'none',
          }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(''),
            mask: (
              <div
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex: 1001, // Ensure the z-index is higher than the modal
                  backgroundColor: 'rgba(0, 0, 0, 0.85)',
                }}
              />
            ),
          }}
          src={previewImage}
        />
      )}
    </>
  );
}