import './LoginPage.css'
import React, { useEffect, useState } from 'react'

import Navbar from "../components/Navbar.jsx"
import LoginForm from "../components/LoginForm.jsx"
import RegisterForm from "../components/RegisterForm.jsx"
import Footer from "../components/Footer.jsx"

import Background from "../assets/images/login-background.png"
import CheckIcon from "../assets/icons/check-mark.svg"


export default function LoginPage() {

    const [isLogin, setIsLogin] = useState(true);

    useEffect(() => {
        const hash = window.location.hash;
        setIsLogin(hash !== "#Register");
    }, []);

    const handleToggleForm = () => {
        setIsLogin(!isLogin);
        window.location.hash = isLogin ? "Register" : "Login";
    };

    return(
        <div className="login-page-container">
            <Navbar/>
            <img src={Background} alt="Background" className='background-image'/>
            <div className="ad-form-container">
                <div className="ad-container">
                    <h3 className="ad-title">Register as a member of Alta Vista now!</h3>
                    <p className='ad-phrase'>You can use your account to:</p>
                    <ul className='account-uses'>
                        <li>
                            <img src={CheckIcon} alt="Check" className='check-icon'/>
                            Reserve tee times online
                        </li>
                        <li>
                            <img src={CheckIcon} alt="Check" className='check-icon'/>
                            View available booking slots
                        </li>
                        <li>
                            <img src={CheckIcon} alt="Check" className='check-icon'/>
                            Manage bookings
                        </li>
                    </ul>
                </div>
                <div className='input-form-container'>
                    {isLogin ? <LoginForm onToggleForm={handleToggleForm} /> : <RegisterForm onToggleForm={handleToggleForm} />}
                </div>
            </div>
            <Footer />
        </div>
    );
}