import './Footer.css';
import React from 'react';
import AltaVistaLogo from "../assets/icons/alta-vista-icon-2.svg"
import InstagramLogo from "../assets/icons/instagram-icon.svg"
import FacebookLogo from "../assets/icons/facebook-icon.svg"
import AddressLogo from "../assets/images/location-icon-footer.png"
import PhoneLogo from "../assets/images/phone-icon-footer.png"

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-section">
        <span className="big-text">
          <div className='row'>
            <div className='col altavista-col'>
              <img src={AltaVistaLogo} alt="Logo" className='altavista-logo'/>
              <div className="altavista-title-footer-container">
                <div className='row altavista-title'>ALTA VISTA</div> 
                <div className='row altavista-description'>Golf and & Country Club</div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-1'><br />
              <img src={PhoneLogo} width={20} alt="Phone Icon"/> 
              <div class='col phone-col'> <br />
                <a href='tel:+639173015422' class='phone-number-footer'>+632 263 5533-35</a>
                <div class='phone-description'>Landline</div>
              </div>
            </div>
            

          </div>
        </span>
        <div className='row mt-1'>
          <div className='col-1'>
            <img src={AddressLogo} width={20} alt="Address Icon" />
            <div class='col location-col'>
              <div class='location-name'><a href='https://www.google.com/maps/search/Pardo+Cebu+City,+Cebu' class='location-name'>Pardo Hills</a></div>
              <a href='https://www.google.com/maps/search/Pardo+Cebu+City,+Cebu' class='location-description'>Pardo Cebu City, Cebu</a>
          </div>
          </div>

        </div>
        <div>
          <div className='last-row'><br /><br/>
            <div className='regular-font'>
              ©2024 ALTA VISTA | All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
      {/* Other footer sections */}
      <div className="footer-section"><br /><br />
        <h5 className="footer-title">Golf Registration</h5>
        <div className="footer-info">
          <div>+63945-431-3239</div>
          <a href="mailto:altavista_golf@yahoo.com">altavista_golf@yahoo.com</a>
        </div>
        <br/><br />
        <h5 className="footer-title">Golfers' Lounge</h5>
        <div className="footer-info">
          <div>+63908-881-6754</div>
        </div>
      </div>
      {/* Remaining footer sections */}
      <div className="footer-section"><br /><br />
        <h5 className="footer-title">Food and Beverage</h5>
        <div className="footer-info">
          <div>+632 263 5536</div>
          <div>+63917-118-8492</div>
          <a href="mailto:altavista_foodandbev@yahoo.com">altavista_foodandbev@yahoo.com</a>
        </div>
        <br/><br />
        <h5 className="footer-title">Sports</h5>
        <div className="footer-info">
          <div>+63995-031-5917</div>
        </div>
      </div>
      <div className="footer-section"><br /><br />
        <h5 className="footer-title">Accounting</h5>
        <div className="footer-info">
          <div>+632 263-5537</div>
          <div>+63917-301-5039</div>
        </div>
        <br/>
        <h5 className="footer-title">Membership</h5>
        <div className="footer-info">
          <div>+63917-301-5422</div>
          <a href="mailto:membership@altavistagolfcebu.com">membership@altavistagolfcebu.com</a>
        </div>
      </div>
      <div className="footer-section"><br /><br />
        <h5 className="footer-title">Links</h5>
        <div className="footer-info">
          <div ><a href="/">About Us</a></div>
          <div><a href="/Facilities">Facilities</a></div>
          <div><a href="/Announcements">Announcements</a></div>
          <div><a href="/Admin-Login">Admin Login</a></div>
        </div>
      </div>
      <div className="footer-section"><br /><br />
        <h5 className="footer-title">Social Media</h5>
        <div className="footer-info">
        <div class="social-media-info"> 
            <a href="https://www.facebook.com/altavistagolfandcountryclubofficial">
                <img src={FacebookLogo} width="20" alt="Facebook Logo"/>
                Alta Vista Golf and Country Club
            </a>
        </div>
        <div>
          <a href="https://www.instagram.com/altavistagolfclubcebubanquet/">
              <img src={InstagramLogo} width="20" alt="Email Icon"/>
              Alta Vista Cebu
          </a>
        </div>
        </div>
        <div className='regular-font'><br /><br /><br /><br /><br /><br /><br /><br /><br />
          <a href="https://mytsoftdevsolutions.com/">Powered by MYT SoftDev Solutions, Inc.</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
