import './Hero.css';
import React, { useRef, useEffect } from 'react';

import PictureOne from '../assets/images/picture-1.png';
import PictureTwo from '../assets/images/picture-2.png';
import PictureThree from '../assets/images/picture-3.png';
import PictureFour from '../assets/images/picture-4.png';
import PictureFive from '../assets/images/picture-5.png';

const images = [PictureOne, PictureTwo, PictureThree, PictureFour, PictureFive,PictureOne, PictureTwo, PictureThree, PictureFour, PictureFive];

export default function Hero() {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    let currentIndex = 0;

    const slideImages = () => {
      currentIndex = (currentIndex + 1) % images.length;
      container.style.transition = 'transform 1s ease-in-out'; // Add transition effect
      container.style.transform = `translateX(-${currentIndex * 100}%)`;

      // Reset to the first image if reached the end
      if (currentIndex === images.length - 1) {
        setTimeout(() => {
          container.style.transition = 'none'; // Remove transition for instant reset
          container.style.transform = 'translateX(0)';
          currentIndex = 0;
        }, 1000); // Wait for transition before resetting
      }
    };

    const intervalId = setInterval(slideImages, 5000); // Change image every 3 seconds

    return () => clearInterval(intervalId);
  }, []);

  function handleClick() {
    window.location.href = "/Member-Login#Register";
}

  return (
    <div>
        <div className="slideshow-container">
          <div className="slideshow" ref={containerRef}>
              {images.map((image, index) => (
              <img key={index} src={image} alt={`Slide ${index + 1}`} className="slide" />
              ))}
          </div>
        </div>
        
        <div className="hero-content">
            <h1 className='hero-title'>Alta Vista Golf & Country Club</h1>
            <p className='hero-slogan'>Pardo Hills, Cebu City</p>
            <button className='hero-button' onClick={handleClick}>Become a Member</button>
        </div>
    </div>

  );
}
